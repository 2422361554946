.assignment-modal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
}

.assignment-modal-show {
  display: block;
}

.assignment-modal-content {
  background-color: white;
  margin: 2% auto;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  position: relative;
  animation: modalFadeIn 0.3s ease-in;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.assignment-close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  color: #555;
  cursor: pointer;
}

.assignment-form-group {
  margin-bottom: 15px;
  margin-top: 1em;
}

.assignment-form-group label {
  font-size: 1.2rem;
  color: #0961a2;
}

.assignment-form-group input,
.assignment-form-group textarea {
  width: 100%;
  padding: 10px 0 10px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.assignment-drag-drop {
  border: 2px dashed #0961a2;
  padding: 20px;
  text-align: center;
  color: #0961a2;
}

.assignment-form-actions {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.assignment-form-actions button {
  background-color: #0072ff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.assignment-form-actions button:hover {
  background-color: #00c6ff;
}


.datepicker-container {
  display: block !important;
  margin: 20px;
}

.custom-datepicker-input {
  width: 200px;
  padding: 10px;
  border: 2px solid #4a90e2;
  border-radius: 8px;
  font-family: 'Nunito Sans', sans-serif; /* Use custom font */
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.custom-datepicker-input:focus {
  border-color: #062784; /* Change border on focus */
  outline: none;
}
