.StudentProfileSection {
  background-color: #f4f7fa;
}

.Profile_Header {
  margin-bottom: 20px;
}

.search-bar {
  padding: 10px 50px;
  font-size: 16px;
  border-radius: 25px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.search-bar:focus {
  border-color: #81B9E8;
  outline: none;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.table-container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-paper {
  box-shadow: none;
}

.progress-bar {
  background-color: #eee;
  border-radius: 4px;
  height: 10px;
  width: 100%;
  overflow: hidden;
}

.progress {
  background-color: #81B9E8;
  height: 100%;
}

.action-buttons {
  display: flex;
  justify-content: center;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
