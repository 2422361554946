@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

.animated-background-section {
  width: 100%;
  height: 160px;
  margin: 0;
  padding: 0;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.upcoming-lecture-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.context {
  width: 100%;
  position: absolute;
  margin-top: 2em;
}

.context p {
  text-align: left;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  margin-left: 1em;
  margin-top: 0;
  margin-bottom: 0;
}
.join-now-meeting-btn {
  width: 250px;
  margin-right: 1em;
}

.join-now-meeting-btn > button,
.join-now-meeting-btn > button *,
.join-now-meeting-btn > button :after,
.join-now-meeting-btn > button :before,
.join-now-meeting-btn > button:after,
.join-now-meeting-btn > button:before {
  border: 0 solid;
  box-sizing: border-box;
}

.join-now-meeting-btn > button {
  scale: 0.5;
  -webkit-tap-highlight-color: transparent;
  -webkit-appearance: button;
  background-color: #000;
  background-image: none;
  color: #fff;
  cursor: pointer;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 100%;
  line-height: 1.5;
  margin: 0;
  -webkit-mask-image: -webkit-radial-gradient(#000, #fff);
  padding: 0;
  position: relative;
  z-index: 9;
}

.join-now-meeting-btn > button:disabled {
  cursor: default;
}

.join-now-meeting-btn > button:-moz-focusring {
  outline: auto;
}

.join-now-meeting-btn > button svg {
  display: block;
  vertical-align: middle;
}

.join-now-meeting-btn > button [hidden] {
  display: none;
}

.join-now-meeting-btn > button {
  border: 1px solid;
  border-radius: 999px;
  box-sizing: border-box;
  display: block;
  font-weight: 900;
  overflow: hidden;
  padding: 1.2rem 3rem;
  position: relative;
  text-transform: uppercase;
  width: 300px;
  padding: 1.1em;
  font-size: 1.6em;
}

.join-now-meeting-btn > button .original {
  background: #fff;
  color: #000;
  display: grid;
  inset: 0;
  place-content: center;
  position: absolute;
  transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1);
}

.join-now-meeting-btn > button:hover .original {
  transform: translateY(100%);
}

.join-now-meeting-btn > button .letters {
  display: inline-flex;
}

.join-now-meeting-btn > button span {
  opacity: 0;
  transform: translateY(-15px);
  transition: transform 0.3s cubic-bezier(0.87, 0, 0.13, 1), opacity 0.3s;
}

.join-now-meeting-btn > button span:nth-child(2n) {
  transform: translateY(15px);
}

.join-now-meeting-btn > button:hover span {
  opacity: 1;
  transform: translateY(0);
}

.join-now-meeting-btn > button:hover span:nth-child(2) {
  transition-delay: 0.1s;
}

.join-now-meeting-btn > button:hover span:nth-child(3) {
  transition-delay: 0.2s;
}

.join-now-meeting-btn > button:hover span:nth-child(4) {
  transition-delay: 0.3s;
}

.join-now-meeting-btn > button:hover span:nth-child(5) {
  transition-delay: 0.4s;
}

.join-now-meeting-btn > button:hover span:nth-child(6) {
  transition-delay: 0.5s;
}

.area {
  background: #4e54c8;
  background: -webkit-linear-gradient(to left, #8f94fb, #4e54c8);
  width: 100%;
  height: 160px;
  border-radius: 20px;
  overflow: hidden;
}

.circles {
  position: relative;
  top: 0;
  left: 0;
  height: 100%;
  overflow: hidden;
  margin: 0;
}

.circles li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
  overflow: hidden;
}

.circles li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.circles li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.circles li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.circles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.circles li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.circles li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.circles li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.circles li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.circles li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.circles li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.upcoming-lecture-title > h5{
    font-size: 1.2em;
    margin-block: 0;
    margin-left: 1.2em;
    margin-bottom: 0.5em;
}

/* MEDIA QUERY */

@media (max-width: 575px) {
    .upcoming-lecture-section{
        display: block;
        margin-top: 1em;
    }
    .context{
        margin-top: 0;
    }
    .context  p{
        font-size: 1.2em;
        margin-block: 0;
        margin-top: 1em;
    }
    .join-now-meeting-btn{
        width: 100px;
        margin-right: 0;
        /* margin-bottom: 4em; */
        text-align: left;
        height: 90px;
    }
    .join-now-meeting-btn > button{
        width: 200px;
        margin-left: -1.3em;
        height: 94px;

    }
}

/**
   * responsive for large than 768px screen
   */

@media (max-width: 768px) {
    .upcoming-lecture-section{
        display: block;
        margin: 1em;
    }
    .context p{
        margin: 0;
        margin-left:1em ;
    }
    .join-now-meeting-btn > button{
        margin-left: -1.2em;
    }
}

@media (max-width: 992px) {
    .upcoming-lecture-section{
        display: block;
        margin: 1em;
    }
    .context p{
        margin: 0;
        margin-left:1em ;
    }
    .join-now-meeting-btn > button{
        margin-left: -1.2em;
    }
}

/**
   * responsive for large than 1200px screen
   */

@media (max-width: 1200px) {
}
