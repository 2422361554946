.unit-card-container {
    display: flex;
    flex-direction: column; /* Change to column for better alignment */
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1em;
    margin-bottom: 2em;
  }
  
  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 600px;
  }
  
  .fullscreen-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 1000; /* Ensure the button is above the iframe */
  }
  
  .unit-card-long {
    width: 80%;
    text-align: left;
  }
  
  .unit-desc-title {
    font-weight: bolder;
    font-size: 1.6em;
    color: #E89F3C;
    text-align: center;
  }
  
  .unit-card-long > p {
    margin-bottom: 0 !important;
  }
  
  .unit-card-long > .description {
    font-weight: 300;
    margin-top: 0;
  }
  
  .carousel-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative; /* Position relative for button positioning */
  }
  
  .unit-cards-section {
    display: flex;
    overflow: hidden; /* Hide overflow to maintain card visibility */
    width: 80%; /* Ensure it holds 3 cards perfectly */
    justify-content: center;
    padding-bottom: 1em;
    gap: 1.4em;
    transition: transform 0.5s ease-in-out; /* Smooth animation for sliding */
  }
  
  .cards-slider {
    display: flex; /* Allows cards to be laid out in a row */
    transition: transform 0.5s ease-in-out; /* Animation for sliding effect */
  }
  
  .prev-button, .next-button {
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    padding: 10px;
    border-radius: 40%; /* Make buttons circular */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10; /* Ensure buttons are above the cards */
    transition: background-color 0.3s ease-in-out; /* Smooth background change on hover */
  }
  
  .prev-button {
    left: 50px; /* Position left */
  }
  
  .next-button {
    right: 50px; /* Position right */
  }
  
  .prev-button:hover, .next-button:hover {
    background-color: rgba(0, 0, 0, 0.8); /* Darker on hover */
  }
  
  .prev-button[disabled], .next-button[disabled] {
    opacity: 0.5; /* Make disabled buttons transparent */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
  }
  
  /* Scrollbar styles for better visuals */
  .unit-cards-section::-webkit-scrollbar {
    height: 8px; /* Height of the scrollbar */
  }
  
  .unit-cards-section::-webkit-scrollbar-thumb {
    background: #888; /* Customize scrollbar color */
    border-radius: 10px; /* Round scrollbar shape */
  }
  
  .unit-cards-section::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker on hover */
  }
  
  /* Card styling */
  .unit-card {
    flex: 0 0 calc(100% / 3); /* Ensure 3 cards are visible at a time */
    box-sizing: border-box;
    padding: 10px; /* Space around cards */
    transition: transform 0.3s ease; /* Animation for hover effect */
  }
  
  /* Add hover effect for cards */
  .unit-card:hover {
    transform: scale(1.05); /* Scale up the card slightly on hover */
  }
  