.attendance-form-container {
    max-width: 1000px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  
  label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type='date'],
  select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .submit-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #EB9F3D;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 2em;
  }
  
  .submit-btn:hover {
    background-color: #fc9611;
  }
  
  .student-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .student-table th,
  .student-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .student-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .student-table td select {
    width: 100%;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  