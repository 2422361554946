/* AttendanceModal.css */
.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .modal-title {
    font-weight: bold;
  }
  
  .close-button {
    color: #555;
  }
  
  .modal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
  }
  