.unit-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  height: 200px;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  min-width: 290px;
  max-width: 290px;
  margin-top: 3em;
}
.unit-card-img-container {
  width: 100%;
  height: 60%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}
.unit-card-img-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.unit-card-desc-duration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20%;
}
.unit-card-desc-duration > p {
  font-size: 0.9em;
  font-weight: 700;
  color: rgb(149, 149, 149);
}
.unit-card-content-title {
  display: flex; 
  width: 100%;
  padding: 0 0 0.8em 0.2em;
  text-align: left;
  text-wrap: wrap;
  word-wrap: break-word;
}
.unit-card-content-title > p {
  font-size: 1em;
  font-weight: 800;
  color: rgb(0, 0, 0);
  text-align: left;
  text-wrap: wrap;
  word-wrap: break-word;
}
.unit-card-content {
  margin-bottom: 1em;
  /* padding: 1em; */
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Dark semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.play-button {
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.unit-card:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.unit-card:hover .overlay {
  opacity: 1;
}
