.assigned-course-section{
    display: flex;
    flex-direction: row;
}
.assigned-course-container{
    width: 95%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.assigned-course-container-heading{
    width: 95%;
    display: flex;
    padding: 10px;
}
.assigned-course-container-heading > h1{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 800;
}
.assigned-course-card-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}