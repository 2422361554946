/* Overall page container */
.page-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

/* Header styling */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #062784;
  margin-bottom: 20px;
}

.header-left {
  font-size: 2rem;
  font-weight: bold;
}

.header-right {
  position: relative;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown-toggle:focus {
  outline: none;
}

.arrow {
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.arrow.open {
  transform: rotate(180deg);
}

.dropdown-menu {
  position: absolute;
  right: 0;
  background: #fff;
  list-style: none;
  padding: 0;
  margin: 5px 0 0 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 120px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.15);
}

.dropdown-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu li:hover {
  background: #f0f0f0;
}

/* Accordion container */
.accordion-container {
  width: 100%;
}

/* Accordion item */
.accordion-item {
  border: 1px solid #ccc;
  margin-bottom: 15px;
  border-radius: 5px;
  overflow: hidden;
  background: #fff;
}

/* Accordion header */
.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f7f7;
  padding: 15px;
  color: #062784;
  cursor: pointer;
  transition: background 0.3s ease;
}

.accordion-header:hover {
  background: #eaeaea;
}

.accordion-header h3 {
  margin: 0;
  font-size: 1.2rem;
}

/* Dropdown icon in accordion header */
.dropdown-icon {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

/* Accordion body */
.accordion-body {
  padding: 15px;
  border-top: 1px solid #ccc;
}

/* Assessment row */
.assessment-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.assessment-title {
  font-size: 1rem;
  color: rgb(40, 47, 91);
  font-weight: bolder;
}

/* Take Assessment button */
.take-assessment-button {
  background: #EA9D3D;
  color: #fff;
  padding: 6px 10px;
  text-decoration: none;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: background 0.3s ease;
}

.take-assessment-button:hover {
  background: #218838;
}
