.pagination-nav {
    margin-top: 20px;
    text-align: center;
  }
  
  .pagination {
    display: inline-block;
  }
  
  .pagination li {
    display: inline;
    margin: 0 5px;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #0961a2;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination li:hover {
    background-color: #0072ff;
  }
  