.assigned-course-card {
  width: 90%;
  height: 200px;

  border-radius: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  background-image: linear-gradient(
    30deg,
    hsl(238deg 45% 34%) 0%,
    hsl(229deg 52% 34%) 8%,
    hsl(223deg 58% 35%) 16%,
    hsl(217deg 65% 35%) 23%,
    hsl(213deg 72% 34%) 31%,
    hsl(209deg 81% 34%) 38%,
    hsl(205deg 89% 34%) 46%,
    hsl(200deg 100% 34%) 54%,
    hsl(198deg 81% 40%) 63%,
    hsl(196deg 60% 47%) 71%,
    hsl(193deg 52% 54%) 80%,
    hsl(189deg 51% 61%) 90%,
    hsl(184deg 49% 68%) 100%
  );
}
.assigned-course-heading {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
  margin-left: 1em;
  text-align: center;
}
.assigned-course-name {
  font-size: 1.5em;
  font-weight: 800;
  color: white;
  margin-bottom: 0;
}
.assigned-course-description {
  font-size: 1em;
  font-weight: 500;
  color: white;
  width: 90%;

}
.assigned-course-description > p{

}
.assigned-course-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1em;
}
.assigned-course-details-btn {
  background-color: transparent;
  padding: 0.5em;
  border: 2px solid white;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  color: white;
  width: 10em;
}

.see-more-btn {
  display: flex;
  color: #f3f4f5;
  cursor: pointer;
  padding: 0;
  font-size: 0.9rem;
  width: 80px;
}

@media screen and (max-width: 768px) {
  .assigned-course-card {
    flex-direction: column;
    /* align-items: center; */
    margin: 1em;
  }
  .assigned-course-heading {
    margin-left: 1em;
    margin-top: 1em;
  }
  .assigned-course-name {
    font-size: 1.2em;
    margin-bottom: 0;
  }
  .assigned-course-description {
    font-size: 0.8em;
  }
  .assigned-course-details {
    margin-right: 0;
    /* margin-top: 1em; */
    margin-bottom: 1em;
  }
  .assigned-course-details-btn {
    width: 50%;
  }
}
