.assignment-management-main-section {
    padding: 30px;
    background-color: #f5f7fb;
    min-height: 100vh;
    width: 99%;
  }

  .bell-icon {
    margin-top: 1em !important;
    cursor: pointer;
  }
  
  .assignment-management-main-container {
    /* width: 99%;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  }

  .assignment-management-main-heading{
    display: flex;
    justify-content: space-between;
  }
  
  .assignment-management-main-heading p {
    font-size: 1.7em;
    font-weight: 800;
    color: black;
    margin-bottom: 20px;
  }
  
  .assignment-management-main-create-banner {
    margin-bottom: 30px;
  }
  
  .assignment-management-main-assignment-library-heading p {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #0961a2;
  }
  
  .assignment-management-main-assignment-library-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .assignment-management-main-search-bar {
    margin-bottom: 20px;
  }
  