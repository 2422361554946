.assigned-course-details-section{
    width: 100%;
    height: 100%;
    background-color: #F0F0F2;
    overflow-x: hidden;
}
.assigned-course-details-container{
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: column;
}
.assigned-course-details-header-tab-container{
  display: flex;
  gap: 3em;
  align-items: center;
  width: 100%;
  
}
.assigned-course-details-header-tab-container > p{
  font-size: 1.5em;
  font-weight: 800;
  color: black;
  margin-left: 1em;
}
.assigned-course-details-tab{
  background-color: lightgray;
  border-radius: 30px;
  padding: 0.2em;
  display: flex;
  gap: 0.5em;
}
.assigned-course-details-tab > button{
  background-color: lightgray;
  border: none;
  padding: 0.5em;
  cursor: pointer;
  border-radius: 30px;
  font-weight: bold;
}
.assigned-course-details-tab > button.active-tab{
  background-color: white;
  border-radius: 30px;
}
.assigned-course-details-about-course > p{
  font-size: 1em;
  font-weight: 500;
  color: black;
  margin-left: 1.2em;
  
}