.upcoming-classes-container {
    font-family: 'Roboto', sans-serif;
    
  }
  
  .table-container {
    height: 250px;
    margin-top: 20px;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    scrollbar-width: none;
    border: 1px solid #81B9E8; 
  }
  
  .MuiTableCell-root {
    padding: 18px;
    color: #062784;
  }
  
  .text-ellipsis {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #062784;
  }
  
  .text-ellipsis1 {
    max-width: 143px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #062784;
  }
  
  .MuiTablePagination-root {
    display: flex;
    justify-content: flex-end;
    padding: 0 16px;
    color: #062784; 
  }
  
  .MuiTypography-h5 {
    font-weight: 800;
    color: #062784; 
  }
  
  .MuiTableHead-root {
    background-color: #062784; 
  }
  
  .MuiTableCell-head {
    color: #fff !important;
    font-weight: bold;
  }
  

  .MuiTablePagination-actions .MuiButtonBase-root {
    color: #062784; 
  }
  
  .MuiTablePagination-toolbar {
    color: #062784; 
  }
  
  @media (max-width: 600px) {
    .MuiTableCell-root {
      padding: 4px;
      font-size: smaller;

    }


  
    .text-ellipsis {
      max-width: 120px;
    }
  }
  