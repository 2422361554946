.dashboard-cards-container {
    display: flex !important;
    justify-content: space-between !important;
    gap: 10px;
    margin: 20px 0 !important;
  }
  
  .card-item {
    flex: 1 !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease !important;
    cursor: pointer !important;
  }
  
  .dashboard-card {
    background-color: #062784  !important;
    color: #fff !important;
    border-radius: 12px !important;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
    transition: box-shadow 0.3s ease !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 195px !important;
  }
  
  .card-title {
    font-size: 16px !important;
    font-weight: bold !important;
    margin-bottom: 8px !important;
    text-align: center !important;
  }
  
  .card-count {
    font-size: 28px !important;
    text-align: center !important;
    font-weight: 700 !important;
  }
  
  .create-assignment {
    background-color: #E8A03B !important;
  }
  
  .card-item:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  }
  
  @media (max-width: 768px) {
    .dashboard-cards-container {
      flex-direction: column !important;
      gap: 15px !important;
    }
  
    .card-item {
      width: 100%;
    }
  }
  