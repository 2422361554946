.student-main-section {
    width: 100%;
    background-color: #fafafa;
  }
  
  .bell-icon {
    margin-top: 1em !important;
    cursor: pointer;
  }
  
  .student-main-container {
    padding: 1em;
  }
  
  .heading-bell-container {
    display: flex;
    justify-content: space-between;
  }
  
  .student-welcome-heading {
    font-size: 1.7em;
    font-weight: 800;
  }
  
  .student-upcoming-events {
    border: none;
    border-radius: 30px;
    height: 160px;
    object-fit: cover;
  }
  
  .calendar-and-table {
    display: flex;
    gap: 3rem
  }
  .calendar-and-table .Basic-section {
    width: 50%;
  }
  .calendar-and-table .calendar-section {
    width: 42%;
  }
  .Slot-calendar-section {
    margin-top: 3em;
  }
  
  .calendar-and-table{
    gap: 2rem;
  }
  .upload-view-assignment {
    display: flex;
    gap: 2em;
    margin-top: 2em;
  }
  .pending-assignment-section {
    
    width: 50%;
    /* height: 250px; */
  }
  .upload-pending-assignment {
    border-radius: 20px;
    background-color: white;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }
  
  /* MEDIA QUERY */
  
  @media (max-width: 575px) {
    .upload-view-assignment{
      flex-wrap: wrap-reverse
    }
    .pending-assignment-section{
      width: 100%;
  
    }
    .upload-pending-assignment{
      width: 100%;
    }
    .calendar-and-table {
      flex-direction: column;
    }
    .calendar-and-table .Basic-section{
      width: 100%;
    }
    .calendar-and-table .calendar-section{
      width: 100%;
    }
  }
  
  /**
     * responsive for large than 768px screen
     */
  
  @media (max-width: 768px) {
  }
  
  @media (max-width: 992px) {
  }
  
  /**
     * responsive for large than 1200px screen
     */
  
  @media (max-width: 1200px) {
  }
  