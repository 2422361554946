.assignment-view-details-inner-container {
    padding: 0em 2em 0 2em;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 99%;
    height: 100vh;
  }
  
  .assignment-view-details-inner-header {
    text-align: center;
    padding-bottom: 1.5em;
  }
  
  .assignment-view-details-inner-header p {
    font-size: 1.8em;
    font-weight: bold;
    color: #062784;
  }
  
  .assignment-view-details-inner-content-title p,
  .assignment-view-details-inner-content-description p {
    font-size: 1.2em;
    margin-bottom: 1.2em;
  }
  
  .view-student-assignment-table {
    margin-top: 1.5em;
    overflow-x: auto;
  }
  
  .student-assignment-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
  }
  
  .student-assignment-table th, .student-assignment-table td {
    padding: 12px 15px;
    border: 1px solid #ddd;
  }
  
  .student-assignment-table th {
    background-color: #062784;
    color: white;
  }
  
  .student-assignment-table td a {
    color: #337ab7;
    text-decoration: none;
  }
  
  .student-assignment-table td a:hover {
    text-decoration: underline;
  }
  
  .student-assignment-table input[type="number"] {
    width: 90%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .student-assignment-table button {
    padding: 8px 12px;
    background-color: #062784;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .student-assignment-table button:hover {
    background-color: #0541a1;
  }
  
  /* Responsive design for mobile */
  @media (max-width: 768px) {
    .assignment-view-details-inner-container {
      padding: 1em;
    }
  
    .student-assignment-table th, .student-assignment-table td {
      padding: 8px 10px;
    }
  
    .assignment-view-details-inner-header p {
      font-size: 1.5em;
    }
  }
  