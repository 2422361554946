.assignment-banner {
    background: linear-gradient(-45deg, #00c6ff, #0072ff, #0961A2, #ffffff);
    background-size: 400% 400%;
    animation: gradientShift 15s ease infinite;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    color: white;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }
  
  @keyframes gradientShift {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .create-assignment-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #E99E41;
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .create-assignment-button:hover {
    background-color: #ff910a;
  }
  