.assignment-library-card {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .assignment-library-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .assignment-library-card-header p {
    font-size: 1.2rem;
    font-weight: bold;
    color: #0961a2;
  }
  
  .assignment-library-card-body p {
    margin: 10px 0;
    color: #555;
  }
  
  .view-assignment-button {
    background-color: #0961a2;
    color: white;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .view-assignment-button:hover {
    background-color: #0072ff;
  }
  