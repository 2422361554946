.login-form-container {
  width: 40%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  color: white;
  position: relative;
  /* left: 24em;
  top: 2em; */
  padding: 1em;
}
.login-form-container .welcome-message {
  width: 100%;
  margin: auto;
  text-align: center;
}
.login-form-container .welcome-message h1 {
  font-size: 3em;
  margin-bottom: 1em;
}
.login-form-container .welcome-message p {
  font-size: 1.2em;
  margin-bottom: 2em;
}
.login-form-container .login-form {
  width: 100%;
  margin: auto;
  text-align: center;
}
.login-form-container .login-form form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* margin-top: 2em; */
}
.login-form-container .login-form form input {
  width: 60%;
  padding: 1em;
  margin-bottom: 1em;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1em;
}
.login-form-container .login-form form button {
  width: 60%;
  padding: 0.5em;
  border-radius: 5px;
  background-color: #062784;
  color: white;
  border: none;
  outline: none;
  font-size: 1.2em;
  cursor: pointer;
  margin-bottom: 2em;
}
.login-form-container .login-form form button:hover {
  background-color: #004e92;
}
.login-btn:disabled {
  background-color: #999;
  cursor: not-allowed;
}
.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top: 3px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and  (max-width: 768px) {

  .login-form-container {
    width: 87%;
  }
  
}