.unit-detail-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: #f0f0f0;
    /* height: 100vh; */
}

.iframe-wrapper {
    width: 80%;
    max-width: 1200px;
    margin-top: 20px;
    border: 2px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

iframe {
    width: 100%;
    height: 600px;
    border: none;
}

.fullscreen-button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.fullscreen-button:hover {
    background-color: #0056b3;
}
